import React from 'react';

function Table2() {
  return (
    <table
      style={{
        border: "none",
        marginLeft: "-.25pt",
        borderCollapse: "collapse",
        width: '100%'
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Group
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: 'center'
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Native languages
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Dyad (n)
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Genre
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Speaker
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Word
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                token
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Word&nbsp;
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                type
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Type-token ratio
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Lexical diversity
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                1
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                HKC
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                29
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                CDS
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Mothers
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                4,097.10
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                446.76
              </span>
            </p>
          </td>
          <td
            rowSpan={2}
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.11
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.32
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                63.24
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Children
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                490.76
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                137.24
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                38.80
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                ADS
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Mothers
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                571.74
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                160.38
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.48
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                364.42
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "none",
              borderBottom: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderTop: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                2
              </span>
            </p>
          </td>
          <td
            style={{
              borderRight: "none",
              borderBottom: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderTop: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Putonghua
              </span>
            </p>
          </td>
          <td
            style={{
              borderRight: "none",
              borderBottom: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderTop: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                18
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                CDS
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Mothers
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                4,056.63
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                465.25
              </span>
            </p>
          </td>
          <td
            rowSpan={2}
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.13
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.33
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                62.83
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Children
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                634.81
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                173.69
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                49.43
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                ADS
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Mothers
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                601.60
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                160.59
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.46
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                244.27
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                3
              </span>
            </p>
          </td>
          <td
            rowSpan={2}
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Urdu, Punjabi, Sindhi or Tamil
              </span>
            </p>
          </td>
          <td
            rowSpan={2}
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                12
              </span>
            </p>
          </td>
          <td
            rowSpan={2}
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                CDS
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Mothers
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                2,536.27
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                321.82
              </span>
            </p>
          </td>
          <td
            rowSpan={2}
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.16
              </span>
            </p>
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.38
              </span>
            </p>
          </td>
          <td
            style={{
              border: "none",
              padding: "0cm 5.4pt",
              height: "15.75pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                57.81
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <br />
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Children
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                868.4
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                185
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                57.49
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                &nbsp;
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "center"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                ADS
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                Mothers
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                515.45
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                132.9
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                0.43
              </span>
            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderImage: "initial",
              borderBottom: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              height: "15pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                lineHeight: "107%",
                fontSize: 15,
                textAlign: "right"
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "107%",
                }}
              >
                118.21
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>

  );
}

export default Table2;
