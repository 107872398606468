import React from 'react';

function Table1() {
  return (
    <table
    style={{
      border: "none",
      marginLeft: "-.25pt",
      borderCollapse: "collapse",
      width: '100%'
    }}
  >
    <tbody>
      <tr>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Group
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: 'center'
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Native languages
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Dyad (n)
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Genre
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Speaker
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Utterances
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Word token
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "1pt solid windowtext",
            borderLeft: "none",
            borderBottom: "1pt solid windowtext",
            borderRight: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Duration
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              (hrs: mins)
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              1
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              HKC
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              29
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              CDS
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Mothers
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              23,244
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              118,816
            </span>
          </p>
        </td>
        <td
          rowSpan={2}
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              28 : 29
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Children
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              7,176
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              14,231
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              ADS
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Mothers
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              11,466
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              82,900
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              13 : 18
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              2
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Putonghua
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              18
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              CDS
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Mothers
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              14,361
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              64,879
            </span>
          </p>
        </td>
        <td
          rowSpan={2}
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              15 : 57
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Children
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              4,345
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              10,156
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              ADS
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Mothers
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              7,216
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              52,337
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              10 : 10
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              3
            </span>
          </p>
        </td>
        <td
          rowSpan={3}
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Urdu, Punjabi, Sindhi or Tamil
            </span>
          </p>
        </td>
        <td
          rowSpan={3}
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              12
            </span>
          </p>
        </td>
        <td
          rowSpan={2}
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              CDS
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Mothers
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              6,749
            </span>
          </p>
        </td>
        <td
          style={{
            border: "none",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              27,872
            </span>
          </p>
        </td>
        <td
          rowSpan={2}
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.75pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              10 : 22
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Children
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              2,870
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              8,617
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.25pt",
            verticalAlign: "top"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.25pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              ADS
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.25pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              Mothers
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.25pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              5,402
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.25pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "right"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              30,787
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderImage: "initial",
            borderBottom: "1pt solid windowtext",
            padding: "0cm 5.4pt",
            height: "15.25pt",
            verticalAlign: "top"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: 15,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 16,
                lineHeight: "107%",
              }}
            >
              7 : 58
            </span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  


  );
}

export default Table1;
