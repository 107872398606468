import React from 'react';

function Table3() {
  return (
    <table
  style={{
    border: "none",
    marginLeft: "-.25pt",
    borderCollapse: "collapse",
    width: '100%'
  }}
>
  <tbody>
    <tr>
      <td
        style={{
          borderTop: "1pt solid windowtext",
          borderLeft: "none",
          borderBottom: "1pt solid windowtext",
          borderRight: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Group
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "1pt solid windowtext",
          borderLeft: "none",
          borderBottom: "1pt solid windowtext",
          borderRight: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: 'center'
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Native languages
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "1pt solid windowtext",
          borderLeft: "none",
          borderBottom: "1pt solid windowtext",
          borderRight: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Dyad (n)
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "1pt solid windowtext",
          borderLeft: "none",
          borderBottom: "1pt solid windowtext",
          borderRight: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Genre
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "1pt solid windowtext",
          borderLeft: "none",
          borderBottom: "1pt solid windowtext",
          borderRight: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Speaker
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "1pt solid windowtext",
          borderLeft: "none",
          borderBottom: "1pt solid windowtext",
          borderRight: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            MLU
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            1
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            HKC
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            29
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            CDS
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Mothers
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            5.173
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Children
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            1.883
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            ADS
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Mothers
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            9.795
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            2
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Putonghua
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            18
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            CDS
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Mothers
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            4.550
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Children
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            2.073
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            ADS
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Mothers
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            9.099
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15.75pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            3
          </span>
        </p>
      </td>
      <td
        rowSpan={3}
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.75pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Urdu, Punjabi, Sindhi or Tamil
          </span>
        </p>
      </td>
      <td
        rowSpan={3}
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.75pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            12
          </span>
        </p>
      </td>
      <td
        rowSpan={2}
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.75pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            CDS
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15.75pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Mothers
          </span>
        </p>
      </td>
      <td
        style={{
          border: "none",
          padding: "0cm 5.4pt",
          height: "15.75pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            3.961
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Children
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            2.115
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.25pt",
          verticalAlign: "top"
        }}
      >
        <br />
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.25pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            ADS
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.25pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            Mothers
          </span>
        </p>
      </td>
      <td
        style={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderImage: "initial",
          borderBottom: "1pt solid windowtext",
          padding: "0cm 5.4pt",
          height: "15.25pt",
          verticalAlign: "top"
        }}
      >
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "107%",
            fontSize: 15,
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 16,
              lineHeight: "107%",
            }}
          >
            6.993
          </span>
        </p>
      </td>
    </tr>
  </tbody>
</table>


  );
}

export default Table3;
