import * as React from "react";

interface MorTag {
  id: number;
  utterance: string;
  mor_tag: string;
  pos: string;
  english: string;
}

type Action = {type: 'SET_QUERY', payload: any} |
  {type: 'STORE_WORD_SEARCH_RESULTS', payload: any} |
  {type: 'SELECT_WORD', payload: {
    selectedWord: MorTag | null
  }} |
  {type: 'SELECT_SENTENCE', payload: {
    selectedSentenceId: number | null,
    selectedFileId: number | null
  }}
type Dispatch = (action: Action) => void
type State = {
  query: string | null;
  selectedWord: MorTag | null;
  wordResults: any;
  selectedSentenceId: number | null;
  selectedFileId: number | null;
}
type SearchProviderProps = {children: React.ReactNode}
const SearchStateContext = React.createContext<State | undefined>(undefined);
const SearchDispatchContext = React.createContext<Dispatch | undefined>(undefined,);

const searchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_QUERY": {
      return {
        ...state,
        query: action.payload.query,
      };
    }

    case "SELECT_WORD": {
      return {
        ...state,
        selectedWord: action.payload.selectedWord
      }
    }

    case "STORE_WORD_SEARCH_RESULTS": {
      return {
        ...state,
        wordResults: action.payload.wordResults,
      };
    }

    case "SELECT_SENTENCE": {
      return {
        ...state,
        selectedSentenceId: action.payload.selectedSentenceId,
        selectedFileId: action.payload.selectedFileId
      }
    }

    default: {
      return state;
    }
  }
};

const SearchProvider = ({ children }: SearchProviderProps) => {
  const [state, dispatch] = React.useReducer(searchReducer, {
    query: "",
    wordResults: null,
    selectedWord: null,
    selectedSentenceId: null,
    selectedFileId: null
  });

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
};

const useSearchState = () => {
  const context = React.useContext (SearchStateContext)
  if (context === undefined) {
    throw new Error('useSearchState must be used within a SearchProvider')
  }
  return context
}

const useSearchDispatch = () => {
  const context = React.useContext (SearchDispatchContext)
  if (context === undefined) {
    throw new Error('useSearchDispatch must be used within a SearchProvider')
  }
  return context
}

export {
  SearchProvider,
  useSearchState,
  useSearchDispatch
}