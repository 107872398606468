import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { AppRoutes } from './AppRoutes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query'
import { SearchProvider } from './contexts/SearchContext'
// import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient()

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <SearchProvider>
        <QueryClientProvider client={queryClient}>
          <CssBaseline/>
          <AppRoutes />
        </QueryClientProvider>
      </SearchProvider>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
