import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Badge,
  LinearProgress
} from "@material-ui/core";
// import ShortTextIcon from "@material-ui/icons/ShortText";
import PersonIcon from "@material-ui/icons/Person";
import FaceIcon from "@material-ui/icons/Face";
import { useQuery } from "react-query";
// import { useSearchState, useSearchDispatch } from '../contexts/SearchContext'

interface Word {
  id: number;
  chinese: string;
  jyutping: string;
  word_order: number;
  start_time: number;
  end_time: number;
}

interface MorTag {
  id: number;
  utterance: string;
  mor_tag: string;
  pos: string;
  english: string;
}

interface Sentence {
  cha_file_id: number;
  sentence_id: number;
  participant: string;
  utterance: string;
  start_time: number;
  end_time: number;
  mor: string;
  words: Word[];
  mor_tags: MorTag[];
  task_code: string;
}

interface ISentenceResultsList {
  wordMorTag: MorTag;
  onClick: (sentence_id: number, cha_file_id: number) => void;
}

interface SentenceDisplayProps {
  // style: React.CSSProperties;
  sentence: Sentence;
  index: number;
  // highlight?: boolean;
  highlightWord?: string;
  highlightWordId?: number;
  // currentTime: number;
  // onJump: (targetTime: number) => void;
}

const url = "/api/get_sentence_by_mor_tag_id.php";

export default function SentenceResultsList({
  wordMorTag,
  onClick,
}: ISentenceResultsList) {
  const classes = useStyles();

  const { isLoading, error, data } = useQuery(
    [wordMorTag.id],
    () =>
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: wordMorTag.id,
          getWords: false,
          taskCodes: [],
        }),
      }).then((res) => res.json()),
    {
      enabled: true,
    }
  );

  // const {selectedWord} = useSearchState()
  // const searchDispatch = useSearchDispatch()

  function handleClick(sentence_id: number, cha_file_id: number) {
    // console.log(`sentence #${id} clicked`)
    onClick(sentence_id, cha_file_id);
  }

  if (error) {
    return <h1>{JSON.stringify(error)}</h1>;
  }
  return (
    <List dense={true}>
      {wordMorTag && <div className={classes.targetWordContainer}>
      <div className={classes.wordMorTag}>
        <div className={classes.utterance}>{wordMorTag?.utterance}</div>
          {wordMorTag?.english && <div className={classes.english}>{`(${wordMorTag?.english})`}</div>}
          {wordMorTag?.pos && <div className={classes.pos}>{wordMorTag?.pos}</div>}
          {wordMorTag?.mor_tag && <div className={classes.mor_tag}>{wordMorTag?.mor_tag}</div>}
        </div>
      </div>}
      {isLoading && <div className={classes.loading}>
        <LinearProgress className={classes.progress}/>
      </div>}
      {!isLoading && data?.results?.map((v: Sentence, index: number) => (
        <ListItem
          key={v.sentence_id}
          button
          onClick={() => handleClick(v.sentence_id, v.cha_file_id)}
        >
          <SentenceDisplay sentence={v} key={index} index={index} highlightWordId={wordMorTag.id} highlightWord={data?.word?.utterance}/>
          {/*<ListItemText>
            {`#${v.sentence_id}: ${v.utterance} (${v.task_code})`}
          </ListItemText>*/}
        </ListItem>
      ))}
    </List>
  );
}

const SentenceDisplay = ({ sentence, index, highlightWord, highlightWordId }: SentenceDisplayProps) => {
  const classes = useStyles();
  const {
    participant,
    utterance,
    start_time,
    end_time,
    words,
    mor_tags,
  } = sentence;
  return (
    <div className={classes.sentence}>
      <div className={classes.avatar}>
        <IconButton
          classes={{
            root: classes.avatarIcon,
          }}
        >
          <ParticipantIcon child={participant === "CHI"} />
        </IconButton>
        <div className={classes.sentenceIndex}>{`#${index + 1}`}</div>
      </div>
      <div className={classes.timeDisplay}>
        <span>{`${formatTime(start_time / 1000)}`}</span>/
        <span>{`${formatTime(end_time / 1000)}`}</span>
      </div>
      <div className={classes.sentenceText}>
        <div className={classes.sentenceWords}>
          {!words && utterance}
          {words &&
            words.map((v, index) => (
              <WordDisplay key={index} word={v} index={index} highlightWord={highlightWord}/>
            ))}
        </div>
        <div className={classes.sentenceMorTags}>
          {mor_tags &&
            mor_tags.map((v, index) => (
              <div key={index} className={clsx(highlightWordId === v.id && classes.highlightMorTag, classes.morTagContainer)}>
                <span className={classes.morTagUtterance}>{v.utterance}</span>
                {v.utterance !== v.mor_tag && (
                  <span className={classes.morTag}>{v.mor_tag}</span>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const ParticipantIcon = ({ child }: { child: boolean }) => {
  const classes = useStyles();
  return (
    <Badge color="default" overlap="circle">
      {child ? (
        <FaceIcon classes={{ root: classes.participantIconSize }} />
      ) : (
        <PersonIcon classes={{ root: classes.participantIconSize }} />
      )}
    </Badge>
  );
};

const WordDisplay = ({ word, index, highlightWord }: { word: Word; index: number, highlightWord?: string }) => {
  const classes = useStyles();
  const { id, chinese } = word;
  return (
    <div className={clsx(highlightWord === chinese && classes.highlightWord, classes.word)}>
      {`${chinese}`}
      {/*<span className={classes.jyutping}>{jyutping}</span>*/}
    </div>
  );
};

const formatTime = (time: number): string => {
  time = Math.floor(time);
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${hours > 0 ? (hours + "").padStart(2, "0") + ":" : ""}${(
    minutes + ""
  ).padStart(2, "0")}:${(seconds + "").padStart(2, "0")}`;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },

  contentContainer: {
    flex: 1,
    position: "relative",
    width: "100%",
    height: "100%",
    // padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  navigation: {
    padding: theme.spacing(1),
  },
  fileInfo: {
    padding: theme.spacing(1),
  },
  progressContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sentenceContainer: {
    // height: '70vh',
    position: "relative",
    width: "100%",
    height: "100%",
    // flex: 1,
    overflow: "auto",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.info.main,
    // paddingBottom: theme.spacing(2)
  },
  sentenceContents: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 0,
    margin: 0,
    padding: theme.spacing(1),
    height: "95%",
    // marginBottom: theme.spacing(2)
  },
  sentence: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 10,
    fontSize: "larger",
    width: '100%',
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.info.main,
    overflow: "hidden",
  },
  sentenceText: {
    flex: 9,
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    alignItems: "flex-start",
    paddingLeft: 10,
    height: "100%",
    fontSize: "small",
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  sentenceWords: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    overflow: "ellipsis",
  },
  sentenceMorTags: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: "smaller",
    overflow: "ellipsis",
  },
  morTagContainer: {
    display: "inline-block",
    margin: 5,
  },
  highlightMorTag: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  morTagUtterance: {
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
  },
  morTag: {
    color: theme.palette.primary.main,
    marginLeft: 3,
    fontStyle: "italic",
  },
  currentSentence: {
    backgroundColor: theme.palette.action.selected,
  },
  highlightSentence: {
    backgroundColor: theme.palette.warning.main,
  },
  word: {
    display: "inline-block",
    marginRight: 3,
    marginLeft: 3,
    marginTop: 5,
    marginBottom: 5,
    cursor: "pointer",
  },
  jyutping: {
    fontSize: "small",
    marginLeft: 3,
    color: theme.palette.secondary.main,
  },
  currentWord: {
    // backgroundColor: theme.palette.success.main,
    // color: theme.palette.success.contrastText,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  highlightWord: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  waveformContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    width: "100%",
    background: "transparent",
    position: "relative",
    padding: theme.spacing(1),
  },
  loadingContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  wave: {
    width: "100%",
    height: 90,
  },
  audioControlsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  autoScrollEnabledSwitch: {
    fontSize: "small",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playButton: {
    marginRight: 10,
  },
  playRateButton: {
    marginRight: 2,
    marginLeft: 2,
    fontSize: "small",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playTimeDisplay: {
    fontFamily: '"Roboto Mono", "Courier New", "Sans Serif"',
    fontSize: "medium",
    [theme.breakpoints.up("md")]: {
      fontSize: "large",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "x-large",
    },
  },
  timeDisplay: {
    fontFamily: '"Roboto Mono", "Courier New", "Sans Serif"',
    // width: '25ch',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // margin: 3,
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
    fontSize: "xx-small",
    [theme.breakpoints.up("md")]: {
      fontSize: "x-small",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "small",
    },
  },
  playRateControlContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 30,
    borderWidth: 1,
    borderColor: theme.palette.warning.main,
    borderStyle: "solid",
    borderRadius: 5,
    padding: 3,
    paddingLeft: 15,
    justifySelf: "flex-end",
    fontSize: "small",
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playRateValue: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: "small",
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playingIcon: {
    color: theme.palette.text.secondary,
    fontSize: "large",
    [theme.breakpoints.up("md")]: {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "xx-large",
    },
  },
  // nonPlayingIcon: {
  //   color: theme.palette.text.secondary,
  //   width: 36,
  //   height: 36,
  //   // margin: 3,
  //   flex: 0.5,
  // },
  avatar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  avatarIcon: {
    padding: 0,
  },
  sentenceIndex: {
    fontSize: "x-small",
    justifySelf: "flex-end",
  },
  participantIconSize: {
    fontSize: "large",
    [theme.breakpoints.up("md")]: {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "xx-large",
    },
  },
  bottomPadding: {
    height: "30vh",
  },
  targetWordContainer: {
    padding: theme.spacing(2)
  },
  wordMorTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  utterance: {
    fontSize: "larger",
    fontWeight: "bold",
  },
  pos: {
    paddingLeft: 5,
    fontStyle: "italic",
    fontSize: "smaller",
  },
  english: {
    paddingLeft: 3,
  },
  mor_tag: {
    color: theme.palette.primary.main,
    paddingLeft: 20,
    fontSize: "smaller",
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  progress: {
    width: '60%'
  }
}));
