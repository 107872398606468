import React from "react";
// import { useParams } from "react-router-dom";
import { makeStyles, darken, lighten, Theme } from "@material-ui/core/styles";
import {
  Paper,
  IconButton,
  Badge,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import NavBar from "../components/NavBar";
import { useQuery } from "react-query";
import "react-h5-audio-player/lib/styles.css";
import WaveSurfer from "wavesurfer.js";
import clsx from "clsx";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PersonIcon from "@material-ui/icons/Person";
import FaceIcon from "@material-ui/icons/Face";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import NavigationLinks from '../components/NavigationLinks'
import { FixedSizeList } from "react-window";
import throttle from "lodash.throttle";
import { useSearchState } from '../contexts/SearchContext'

// import peaks from '../assets/peaks'

// interface ParamTypes {
//   fileid?: string;
//   wordid?: string;
//   sentenceid?: string;
// }

interface Word {
  id: number;
  chinese: string;
  jyutping: string;
  word_order: number;
  start_time: number;
  end_time: number;
}

interface MorTag {
  id: number;
  utterance: string;
  mor_tag: string;
  pos: string;
  english: string;
}

interface Sentence {
  sentence_id: number;
  participant: string;
  utterance: string;
  start_time: number;
  end_time: number;
  mor: string;
  words: Word[];
  mor_tags: MorTag[];
}

function AudioPage() {
  const classes = useStyles();
  // const params = useParams<ParamTypes>();
  // const { fileid, wordid, sentenceid } = params;
  const {selectedWord, selectedSentenceId, selectedFileId} = useSearchState()
  // const searchDispatch = useSearchDispatch()
  // console.log(`fileid = ${fileid}, wordid=${wordid}, sentenceid=${sentenceid}`);
  console.log(`fileid = ${selectedFileId}, wordid=${selectedWord?.id}, sentenceid=${selectedSentenceId}`);
  // const [fileId] = React.useState(parseInt(fileid || ""));
  // example of highlight sentence and word ID's
  return (
    <Paper className={classes.root} elevation={0}>
      <NavBar />
      <File id={selectedFileId} highlightSentenceId={selectedSentenceId} highlightWordId={selectedWord ? selectedWord.id : null} />
    </Paper>
  );
}

const url = "/api/get_file.php";

const File = ({
  id,
  highlightSentenceId,
  highlightWordId,
}: {
  id: number | null;
  highlightSentenceId: number | null;
  highlightWordId: number | null;
}) => {
  const classes = useStyles();
  const [currentPlayTime, setCurrentPlayTime] = React.useState(0);
  const [
    waveFormContainerCreated,
    setWaveFormContainerCreated,
  ] = React.useState(false);
  // const [waveForm, setWaveForm] = React.useState<WaveSurfer>();
  const [waveFormReady, setWaveFormReady] = React.useState<Boolean>(false);
  const [
    waveFormPercentageLoaded,
    setWaveFormPercentageLoaded,
  ] = React.useState<number>(0);
  const [playing, setPlaying] = React.useState(false);
  const [playRate, setPlayRate] = React.useState(1);
  const [autoScrollEnabled, setAutoScrollEnabled] = React.useState(false);
  const autoScrollEnabledRef = React.useRef<boolean| null>(false)
  const waveForm = React.useRef<WaveSurfer | null>(null)
  const triggerResize = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
  }

  const handleChangeAutoScrollEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`set auto scroll to ${event.target.checked}`)
    setAutoScrollEnabled(event.target.checked);
    autoScrollEnabledRef.current = event.target.checked
  };
  // const [playTimeMap, setPlayTimeMap] = React.useState<{ [key: string]: {sentence: Sentence, index: number}[]}>({});
  // const [
  //   playTimeMapCompleted,
  //   setPlayTimeMapCompleted,
  // ] = React.useState<boolean>(false);
  const waveFormSurfer = React.createRef<HTMLDivElement>();
  const [reportCurrentTimeTimer, setReportCurrentTimeTimer] = React.useState<NodeJS.Timeout>();
  const { isLoading, error, data } = useQuery(
    [id],
    () =>
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, getWords: true }),
      }).then((res) => res.json()),
    {
      enabled: true,
    }
  );
  const handleSetAudioPosition = (targetTime: number) => {
    console.log(`jump to position ${targetTime}`)
    waveForm.current?.pause()
    reportCurrentTimeTimer && clearTimeout(reportCurrentTimeTimer)
    waveForm.current?.setCurrentTime(targetTime);
    setCurrentPlayTime(targetTime * 1000);
    if (!waveForm.current?.isPlaying()) {
      waveForm.current?.play();
    }
  };
  const [
    sentencesContainer,
    setSentencesContainer,
  ] = React.useState<HTMLDivElement>();
  const [
    sentencesContainerHeight,
    setSentencesContainerHeight,
  ] = React.useState(1000);
  // const sentencesRef = React.useRef<HTMLDivElement[]>([])
  // const sentencesContainerRef = React.useRef<HTMLDivElement>(null)
  const sentencesRef = React.useRef<FixedSizeList>(null);
  // const playTimeMap = React.useRef<{
  //   [key: string]: { sentence: Sentence; index: number }[];
  // }>({});

  const changePlayRate = (change: number) => {
    setPlayRate(playRate + change);
    waveForm.current?.setPlaybackRate(playRate + change);
    if (waveForm.current?.isPlaying()) {
      waveForm.current?.pause();
      waveForm.current?.play();
    }
  };

  const useHookWithRefCallback = () => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const setRef = React.useCallback((node) => {
      // const handleResize = () => {
      //   if (node) {
      //     setSentencesContainerHeight(node.clientHeight)
      //   }
      // }
      // window.addEventListener('resize', handleResize)

      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      }

      if (node) {
        console.log(`node present. set height = ${node.clientHeight}`);
        setSentencesContainer(node);
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        setSentencesContainerHeight(node.clientHeight - 20);
        triggerResize()
      }

      // Save a reference to the node
      ref.current = node;
    }, []);

    return [setRef];
  };

  const [sentencesContainerRef] = useHookWithRefCallback();

  // React.useEffect(
  //   () => {
  //     const playingSentenceIndex = data?.results?.sentences?.findIndex((s: Sentence) => 
  //       s.sentence_id === highlightSentenceId
  //     )
  //     sentencesRef.current?.scrollToItem(playingSentenceIndex, "center");
  //   }
  // , [data, highlightSentenceId, sentencesRef])

  React.useEffect(() => {
    const handleResize = throttle(() => {
      if (sentencesContainer) {
        console.log(`new container height: ${sentencesContainer.clientHeight}`);
        setSentencesContainerHeight(sentencesContainer.clientHeight - 20)
      }
    }, 500);
    window.addEventListener("resize", handleResize);
    // setTimeout(handleResize, 5000) // call resize once first
    // setTimeout(() => window.dispatchEvent(new Event('resize')), 10000)
    return () => window.removeEventListener("resize", handleResize);
  }, [sentencesContainer]);

  React.useEffect(() => {
    const waveContainer: unknown = document.getElementById("waveform");
    if (waveContainer && !waveFormContainerCreated) {
      console.log(`create waveform surfer`);
      const waveform = WaveSurfer.create({
        barWidth: 2,
        barHeight: 3,
        cursorWidth: 1,
        container: "#waveform",
        backend: "WebAudio",
        mediaControls: true,
        height: 80,
        responsive: true,
      });
      // setWaveForm(waveform);
      waveForm.current = waveform;

      const reportCurrentTime = () => {
        const currentTime = waveForm.current?.getCurrentTime();
        // console.log(`current time = ${currentTime}`);
        if (currentTime) {
          setCurrentPlayTime(currentTime ? currentTime * 1000 : -1);
          let playingSentenceIndex = data?.results?.sentences?.findIndex((s: Sentence) => {
            return currentTime * 1000 >= s.start_time && currentTime * 1000 < s.end_time
          })
          // console.log(`initial scroll sentence ID #${playingSentenceIndex}`)
          if (playingSentenceIndex < 0) {
            playingSentenceIndex = data?.results?.sentences?.findIndex((s: Sentence, index: number) => {
              if (index > 0) {
                return currentTime * 1000 < s.start_time && currentTime * 1000 > data?.results?.sentences[index - 1]?.end_time
              } else {
                return currentTime * 1000 < s.start_time
              }
            })
          }
          // console.log(`target scroll sentence ID #${playingSentenceIndex}`)
          // console.log(`autoScrollEnabledRef = ${autoScrollEnabledRef.current}`)
          if (playingSentenceIndex >= 0 && autoScrollEnabledRef.current) {
            console.log(`target scroll sentence ID #${playingSentenceIndex}`)
            sentencesRef.current?.scrollToItem(playingSentenceIndex, "center");
          }
        }
        // if (waveForm.current?.isPlaying()) {
        //   reportCurrentTimeTimer && clearTimeout(reportCurrentTimeTimer)
        // }
        const id = setTimeout(reportCurrentTime, 10);
        setReportCurrentTimeTimer(id)
      }

      waveform.on("waveform-ready", () => {
        console.log(`waveform-ready event`);
        // waveform.play();
      });
      waveform.on("ready", () => {
        console.log(`ready event`);
        setWaveFormReady(true);
        // must set waveform to play in order to make clicking on waveform operational
        const currentMuteStatus = waveform.getMute()
        waveform.setMute(true)
        waveform.play();
        setTimeout( () => {
          waveform.pause()
          waveform.setMute(currentMuteStatus)
          const playingSentenceIndex = data?.results?.sentences?.findIndex((s: Sentence) => 
            s.sentence_id === highlightSentenceId
          )
          waveForm.current?.setCurrentTime(data?.results?.sentences[playingSentenceIndex].start_time / 1000);
          sentencesRef.current?.scrollToItem(playingSentenceIndex, "center");
        }, 0)
        triggerResize()
      });
      waveform.on("play", () => {
        setPlaying(true);
        reportCurrentTime();
      });
      waveform.on("loading", throttle((percentage) => {
        console.log(`percent loaded: ${percentage}%`);
        setWaveFormPercentageLoaded(percentage);
      }, 100));
      waveform.on("pause", () => setPlaying(false));
      waveform.on("finish", () => setPlaying(false));

      waveform.load(`/audio/${data?.results?.file_info.media_file}.mp3`);

      setWaveFormContainerCreated(true);
      return () => {
        // waveform.un('ready', onReady)
        console.log(`destory waveform when not needed any more`);
        // waveform.destroy()
      };
    }
    if (!waveContainer) {
      // console.log(`waveform container not exists`);
    } else {
      // console.log(`waveform surfer exists. no need to create`);
      // console.log(data);
      // console.log(waveFormSurfer.current)
      // console.log(waveFormContainerCreated);
    }
    return () => {
      // console.log(`clear timeout`)
      // reportCurrentTimeTimer && clearTimeout(reportCurrentTimeTimer)
    }
  }, [data, sentencesRef, autoScrollEnabled, waveFormContainerCreated, reportCurrentTimeTimer, highlightSentenceId]);

  if (isLoading) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress size={120} />
      </div>
    );
  }
  if (error) {
    return (
      <div className={classes.progressContainer}>{`Error: ${JSON.stringify(error)}`}</div>
    );
  }

  return (
    <div className={classes.contentContainer}>
      <div className={classes.navigation}>
        <NavigationLinks stage='file' beforeNavigate={() => {
          waveForm.current?.unAll()
          waveForm.current?.destroy()
        }}/>
      </div>
      <div className={classes.fileInfo}>
        {`${data?.results?.file_info.task_name}, ${data?.results?.sentences.length} transcribed sentences`}
      </div>
      <div className={classes.waveformContainer}>
        <div id="waveform" ref={waveFormSurfer} className={classes.wave} />
        {!waveFormReady && waveFormPercentageLoaded < 100 && (
          <div className={classes.loadingContainer}>
            <LinearProgressWithLabel value={waveFormPercentageLoaded} />
            {`載入音訊...`}
          </div>
        )}
        {!waveFormReady && waveFormPercentageLoaded === 100 && (
          <div className={classes.loadingContainer}>{`音訊處理中，請稍候...`}</div>
        )}
      </div>
      {waveFormReady && (
        <div className={classes.audioControlsContainer}>
          <FormControlLabel
            classes={{label: classes.autoScrollEnabledSwitch}}
            control={
              <Switch
                checked={autoScrollEnabled}
                onChange={handleChangeAutoScrollEnabled}
                color="primary"
                size="small"
              />
            }
            label="自動捲動"
          />
          <span className={classes.playTimeDisplay}>
            {`${formatTime(currentPlayTime / 1000)}`}
          </span>
          <IconButton
            size="medium"
            className={classes.playButton}
            onClick={() => waveForm.current?.playPause()}
          >
            {playing ? (
              <PauseIcon classes={{ root: classes.playingIcon }} />
            ) : (
              <PlayArrowIcon classes={{ root: classes.playingIcon }} />
            )}
          </IconButton>
          <div className={classes.playRateControlContainer}>
            {`播放速度`}
            <span className={classes.playRateValue}>{`${(
              playRate * 100
            ).toFixed(0)}%`}</span>
            <IconButton
              classes={{ root: classes.playRateButton }}
              onClick={() => {
                changePlayRate(-0.1);
              }}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              classes={{ root: classes.playRateButton }}
              onClick={() => {
                changePlayRate(0.1);
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div className={classes.sentenceContainer} ref={sentencesContainerRef}>
          <React.Fragment>
          {data?.results?.sentences && (
            <FixedSizeList
              ref={sentencesRef}
              width={"100%"}
              height={sentencesContainerHeight}
              itemCount={data?.results?.sentences?.length}
              itemSize={120}
            >
              {({ index, style }: {index: number, style: React.CSSProperties}) => (
                <SentenceDisplay
                  sentence={data?.results?.sentences[index]}
                  key={index}
                  index={index}
                  style={style}
                  currentTime={currentPlayTime}
                  highlight={
                    highlightSentenceId ===
                    data?.results?.sentences[index].sentence_id
                  }
                  highlightWordId={highlightWordId ? highlightWordId : undefined}
                  onJump={handleSetAudioPosition}
                />
              )}
            </FixedSizeList>
          )}
          </React.Fragment>
      </div>
    </div>
  );
};

export default AudioPage;

interface SentenceDisplayProps {
  style: React.CSSProperties;
  sentence: Sentence;
  index: number;
  highlight?: boolean;
  highlightWordId?: number;
  currentTime: number;
  onJump: (targetTime: number) => void;
}

const SentenceDisplay = React.forwardRef<HTMLDivElement, SentenceDisplayProps>(
  (props: SentenceDisplayProps, ref) => {
    const {
      style,
      sentence,
      index,
      highlight,
      highlightWordId,
      currentTime,
      onJump,
    }: {
      style: React.CSSProperties;
      sentence: Sentence;
      index: number;
      highlight?: boolean;
      highlightWordId?: number;
      currentTime: number;
      onJump?: (targetTime: number) => void;
    } = props;
    const classes = useStyles();
    const [togglePlayMe, setTogglePlayMe] = React.useState(false);
    const {
      participant,
      utterance,
      start_time,
      end_time,
      words,
      mor_tags,
    } = sentence;
    const playingMe = currentTime >= start_time && currentTime < end_time;
    if (togglePlayMe !== playingMe) {
      setTogglePlayMe(!togglePlayMe);
    }

    return (
      <div
        style={style}
        ref={ref}
        className={clsx(
          playingMe && classes.currentSentence,
          highlight && classes.highlightSentence,
          classes.sentence
        )}
      >
        <div className={classes.avatar}>
          <IconButton
            classes={{
              root: classes.avatarIcon,
            }}
            onClick={() => onJump(start_time / 1000)}
          >
            <ParticipantIcon
              playing={playingMe}
              child={participant === "CHI"}
            />
          </IconButton>
          <div className={classes.sentenceIndex}>{`#${index + 1}`}</div>
        </div>
        <div
          className={classes.timeDisplay}
          onClick={() => onJump(start_time / 1000)}
        >
          <span>{`${formatTime(start_time / 1000)}`}</span>/
          <span>{`${formatTime(end_time / 1000)}`}</span>
        </div>
        <div className={classes.sentenceText}>
          <div className={classes.sentenceWords}>
            {!words && utterance}
            {words &&
              words.map((v, index) => (
                <WordDisplay
                  key={index}
                  word={v}
                  index={index}
                  currentTime={currentTime}
                  highlight={highlightWordId === v.id}
                  onJump={onJump}
                />
              ))}
          </div>
          <div className={classes.sentenceMorTags}>
            {mor_tags &&
              mor_tags.map((v, index) => (
                <div key={index} className={classes.morTagContainer}>
                  <span className={classes.morTagUtterance}>{v.utterance}</span>
                  {v.utterance !== v.mor_tag && (
                    <span className={classes.morTag}>{v.mor_tag}</span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
);

const ParticipantIcon = ({
  playing,
  child,
}: {
  playing: boolean;
  child: boolean;
}) => {
  const classes = useStyles();
  return (
    <Badge
      color="default"
      overlap="circle"
      {...(playing && { badgeContent: "\u2022\u2022\u2022" })}
    >
      {child ? (
        <FaceIcon classes={{ root: classes.participantIconSize }} />
      ) : (
        <PersonIcon classes={{ root: classes.participantIconSize }} />
      )}
    </Badge>
  );
};

const WordDisplay = ({
  word,
  index,
  currentTime,
  highlight,
  onJump,
}: {
  word: Word;
  index: number;
  currentTime: number;
  highlight?: boolean;
  onJump?: (targetTime: number) => void;
}) => {
  const classes = useStyles();
  const { chinese, start_time, end_time } = word;
  return (
    <div
      className={clsx(
        currentTime >= start_time &&
          currentTime < end_time &&
          classes.currentWord,
        highlight && classes.highlightWord,
        classes.word
      )}
      onClick={() => {
        console.log(`Jumping to position #${start_time / 1000}`);
        onJump && onJump(start_time / 1000);
      }}
    >
      {`${chinese}`}
      {/*<span className={classes.jyutping}>{jyutping}</span>*/}
    </div>
  );
};

const formatTime = (time: number): string => {
  time = Math.floor(time);
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${hours > 0 ? (hours + "").padStart(2, "0") + ":" : ""}${(
    minutes + ""
  ).padStart(2, "0")}:${(seconds + "").padStart(2, "0")}`;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 0,
    margin: 0,
    height: "100vh",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  contentContainer: {
    flex: 1,
    position: "relative",
    width: "100%",
    height: "100%",
    // padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  navigation: {
    padding: theme.spacing(1)
  },
  fileInfo: {
    padding: theme.spacing(1)
  },
  progressContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sentenceContainer: {
    // height: '70vh',
    position: "relative",
    width: "100%",
    height: "100%",
    // flex: 1,
    overflow: "auto",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.info.main,
    // paddingBottom: theme.spacing(2)
  },
  sentenceContents: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: 0,
    margin: 0,
    padding: theme.spacing(1),
    height: "95%",
    // marginBottom: theme.spacing(2)
  },
  sentence: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 10,
    fontSize: "larger",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.info.main,
    overflow: "hidden",
  },
  sentenceText: {
    flex: 9,
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    alignItems: "flex-start",
    paddingLeft: 10,
    height: "100%",
    fontSize: "small",
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  sentenceWords: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    overflow: 'ellipsis'
  },
  sentenceMorTags: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: "smaller",
    overflow: 'ellipsis'
  },
  morTagContainer: {
    display: "inline-block",
    margin: 5,
  },
  morTagUtterance: {
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
  },
  morTag: {
    color: lighten(theme.palette.primary.main, 0.2),
    marginLeft: 3,
    verticalAlign: 'sub',
    fontSize: 'smaller'
    // fontStyle: "italic",
  },
  currentSentence: {
    backgroundColor: theme.palette.action.selected,
  },
  highlightSentence: {
    backgroundColor: theme.palette.warning.main,
  },
  word: {
    display: "inline-block",
    marginRight: 3,
    marginLeft: 3,
    marginTop: 5,
    marginBottom: 5,
    cursor: "pointer",
  },
  jyutping: {
    fontSize: "small",
    marginLeft: 3,
    color: theme.palette.secondary.main,
  },
  currentWord: {
    // backgroundColor: theme.palette.success.main,
    // color: theme.palette.success.contrastText,
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  highlightWord: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  waveformContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    width: "100%",
    background: "transparent",
    position: "relative",
    padding: theme.spacing(1)
  },
  loadingContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  wave: {
    width: "100%",
    height: 90,
  },
  audioControlsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  autoScrollEnabledSwitch: {
    fontSize: "small",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playButton: {
    marginRight: 10,
  },
  playRateButton: {
    marginRight: 2,
    marginLeft: 2,
    fontSize: "small",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playTimeDisplay: {
    fontFamily: '"Roboto Mono", "Courier New", "Sans Serif"',
    fontSize: "medium",
    [theme.breakpoints.up("md")]: {
      fontSize: "large",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "x-large",
    },
  },
  timeDisplay: {
    fontFamily: '"Roboto Mono", "Courier New", "Sans Serif"',
    // width: '25ch',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // margin: 3,
    marginLeft: 5,
    marginRight: 5,
    cursor: "pointer",
    fontSize: "xx-small",
    [theme.breakpoints.up("md")]: {
      fontSize: "x-small",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "small",
    },
  },
  playRateControlContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 30,
    borderWidth: 1,
    borderColor: theme.palette.warning.main,
    borderStyle: "solid",
    borderRadius: 5,
    padding: 3,
    paddingLeft: 15,
    justifySelf: "flex-end",
    fontSize: "small",
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playRateValue: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: "small",
    [theme.breakpoints.up("md")]: {
      fontSize: "medium",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "large",
    },
  },
  playingIcon: {
    color: theme.palette.text.secondary,
    fontSize: "large",
    [theme.breakpoints.up("md")]: {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "xx-large",
    },
  },
  // nonPlayingIcon: {
  //   color: theme.palette.text.secondary,
  //   width: 36,
  //   height: 36,
  //   // margin: 3,
  //   flex: 0.5,
  // },
  avatar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  avatarIcon: {
    padding: 0,
  },
  sentenceIndex: {
    fontSize: "x-small",
    justifySelf: "flex-end",
  },
  participantIconSize: {
    fontSize: "large",
    [theme.breakpoints.up("md")]: {
      fontSize: "x-large",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "xx-large",
    },
  },
  bottomPadding: {
    height: '30vh'
  }
}));
