import React, { ChangeEvent, KeyboardEvent } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import { useQuery } from "react-query";
import { Search } from "@material-ui/icons";
import NavBar from "../components/NavBar";
import WordResultsList from "../components/WordResultsList";
import NavigationLinks from '../components/NavigationLinks'
import { useSearchState, useSearchDispatch } from '../contexts/SearchContext'
// import SentenceResultsList from "../components/SentenceResultsList";

interface WordMorTag {
  id: number,
  utterance: string,
  mor_tag: string,
  english: string,
  pos: string
}

// interface WordResults {
//   results: {
//     word_mor_tag_list: WordMorTag[]
//   }
// }

function SearchPage() {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <NavBar />
      <div className={classes.navigation}>
        <NavigationLinks stage='word'/>
      </div>
      <SearchForm />
    </Paper>
  );
}

const url = "/api/search_word.php";

const SearchForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  // const [query, setQuery] = useSearch("");
  const {query: finalQuery} = useSearchState()
  const searchDispatch = useSearchDispatch()
  const [wordMorTagId, setWordMorTagId] = React.useState<null | number>(null)
  const { isLoading, error, data, refetch } = useQuery(
    [finalQuery],
    () =>
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: finalQuery }),
      }).then((res) => res.json()),
    {
      enabled: false,
    }
  );

  React.useEffect(
    () => {
      refetch()
    }
  , [finalQuery, refetch])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  };
  const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleQuery();
    }
  };
  const handleQuery = () => {
    console.log(`Begin query: ${query}`);
    searchDispatch({
      type: 'SET_QUERY',
      payload: {
        query
      }
    })
    // refetch()
  };

  function handleClick(id: number) {
    console.log(`word #${id} clicked`)
    searchDispatch({
      type: 'SELECT_WORD',
      payload: {
        selectedWord: data?.results?.word_mor_tag_list?.find((v: WordMorTag) => v.id === id)
      }
    })
    setWordMorTagId(id)
    history.push(`/sentences/${id}`);
  }

  /*
  function handleSentenceClick(sentence_id: number, cha_file_id: number) {
    console.log(`sentence #${sentence_id} clicked, for file #${cha_file_id}`)
    history.push(`/file/${cha_file_id}`)
  }
  */

  if (error) {
    return <h1>{JSON.stringify(error)}</h1>;
  }
  return (
    <div className={classes.contentContainer}>
      <TextField
        fullWidth
        autoFocus
        label={"Search"}
        value={query}
        onChange={handleChange}
        onKeyPress={handleEnter}
        helperText={error ? JSON.stringify(error) : ''}
        placeholder={`e.g. 仔, boy, hou2`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleQuery}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {data?.results?.word_mor_tag_list && (
        <WordResultsList wordResults={data?.results?.word_mor_tag_list} onClick={handleClick}/>
      )}
      {isLoading && <div className={classes.loading}>
        <LinearProgress className={classes.progress}/>
      </div>}
      {/*wordMorTagId && <SentenceResultsList wordMorTagId={wordMorTagId} onClick={handleSentenceClick}/>*/}
    </div>
  );
};

export default SearchPage;

const useStyles = makeStyles((theme) => ({
  root: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    // borderRadius: 3,
    margin: 0,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'white',
    height: "100vh",
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center'
    // background: 'linear-gradient(45deg, #0f6B8B 30%, #0F8E53 90%)',
    // color: 'white',
  },
  navigation: {
    padding: theme.spacing(1)
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  progress: {
    width: '60%'
  }
}));
