import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, ListItemIcon , ListItemAvatar, Avatar} from "@material-ui/core";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

interface WordMorTag {
  id: number;
  utterance: string;
  mor_tag: string;
  english: string;
  pos: string;
}

interface IWordResultsList {
  wordResults: WordMorTag[];
  onClick: (id: number) => void;
}

export default function WordResultsList({
  wordResults,
  onClick,
}: IWordResultsList) {
  const classes = useStyles();
  function handleClick(id: number) {
    onClick(id);
  }

  return (
    <List dense={true}>
      {wordResults.map((v: WordMorTag) => (
        <ListItem key={v.id} button onClick={() => handleClick(v.id)}>
          <ListItemAvatar>
            <Avatar>
              {`${v.utterance ? v.utterance?.substr(0, 1) : ''}`}
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Word {...v} />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

const Word = ({ id, utterance, pos, english, mor_tag }: WordMorTag) => {
  const classes = useStyles();
  return (
    <div className={classes.wordMorTag}>
      <div className={classes.utterance}>{utterance}</div>
      {english && <div className={classes.english}>{`(${english})`}</div>}
      {pos && <div className={classes.pos}>{pos}</div>}
      {mor_tag && <div className={classes.mor_tag}>{mor_tag}</div>}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: 0,
  },
  wordMorTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  utterance: {
    fontSize: "larger",
    fontWeight: "bold",
  },
  pos: {
    paddingLeft: 5,
    fontStyle: "italic",
    fontSize: "smaller",
  },
  english: {
    paddingLeft: 3,
  },
  mor_tag: {
    color: theme.palette.primary.main,
    paddingLeft: 20,
    fontSize: "smaller",
  },
}));
