import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import GrainIcon from '@material-ui/icons/Grain';
import MenuBookIcon from '@material-ui/icons/MenuBook';

type Stage = 'word' | 'sentence' | 'file' | 'introduction' | ''

interface NavigationLinksProps {
  stage: Stage;
  beforeNavigate?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(0.5)
  },
  link: {
    display: 'flex',
    textDecoration: 'none'
  }
}));

export default function NavigationLinks({ stage, beforeNavigate }: NavigationLinksProps) {
  const classes = useStyles();
  const history = useHistory()
  function handleClick({ stage: target }: { stage: Stage }) {
    if (target === stage) {
      return
      // } else if (stage === 'file' && target === 'sentence') {
      //   history.go(-1)
      // } else if (stage === 'file' && target === 'word') {
      //   history.go(-2)
      // } else if (stage === 'sentence' && target === 'word') {
      //   history.go(-1)
    }
    if (typeof beforeNavigate === 'function') {
      beforeNavigate()
    }
    if (target === 'sentence') {
      history.push('#/sentences')
    } else if (target === 'word') {
      history.push('#/search')
    }
  }
  const goWordSearchStage = () => handleClick({ stage: 'word' })
  const goSentenceStage = () => handleClick({ stage: 'sentence' })

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link href="#/">
        <HomeIcon className={classes.icon} />
        Home
      </Link>
      {stage === 'introduction' && <Typography color="textPrimary" className={classes.link}>
        <MenuBookIcon className={classes.icon} />
        Introduction
      </Typography>}
      {(stage === 'sentence' || stage === 'file') && <Link href="#/search" className={classes.link} onClick={goWordSearchStage}>
        <SearchIcon className={classes.icon} />
        Word Search
      </Link>}
      {(stage === 'file') && <Link href="#/sentences" className={classes.link} onClick={goSentenceStage}>
        <SearchIcon className={classes.icon} />
        Sentences
      </Link>}
      {(stage === 'word' || stage === 'sentence' || stage === 'file') && <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
        {stage === 'word' ? 'Word search' : stage === 'sentence' ? 'Sentences' : 'File'}
      </Typography>}
    </Breadcrumbs>
  );
}
