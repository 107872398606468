import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Paper
} from "@material-ui/core";
import NavBar from "../components/NavBar";
import './InfiniteScrollStyles.css'
import faker from 'faker'
import { FixedSizeList } from "react-window";


function InfiniteScrollPage() {
    const classes = useStyles()

    const data = new Array(10000).fill({}).map((value, id) => (({
      id: id,
      title: faker.lorem.words(5),
      body: faker.lorem.sentences(8)
    })))
    const Row = ({ index, key, style }: any) => (
      <div>
        <div key={key} style={style} className="post">
          <h3>{`${data[index].title}-${data[index].id}`}</h3>
          <p>{data[index].body}</p>
        </div>
      </div>
     )

  return (
    <Paper className={classes.root} elevation={0}>
      <NavBar />
      <h1>Infinite Scroll Page</h1>
      <FixedSizeList
        width={'100%'}
        height={600}
        itemCount={data.length}
        itemSize={200}
      >
        {Row}
      </FixedSizeList>
    </Paper>
  );
}

export default InfiniteScrollPage;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: 0,
      margin: 0,
      height: "100vh",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      overflow: 'hidden'
    },
}))