import React from 'react'
import { Switch, HashRouter, Route } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import SearchPage from './pages/SearchPage'
import AudioPage from './pages/AudioPage'
import IntroPage from './pages/IntroPage'
import SentenceResultPage from './pages/SentenceResultPage'
import InfiniteScrollPage from './pages/InfiniteScrollPage'

export const AppRoutes: React.FC = () => {
    return (
        <HashRouter>
            <Switch>
                <AuthenticatedAppRoutes />
            </Switch>
        </HashRouter>
    )
}

const AuthenticatedAppRoutes: React.FC = () => {
    return (
        <React.Fragment>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/intro" component={IntroPage} />
            <Route path="/search" component={SearchPage} />
            <Route path="/scroll" component={InfiniteScrollPage} />
            <Route path="/sentences" component={SentenceResultPage} />
            <Route path="/file" component={AudioPage} />
        </React.Fragment>
    )
}