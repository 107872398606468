import React from 'react';

function CodingTaggingTable() {
  return (
    <table
      style={{
        marginLeft: "8.75pt",
        borderCollapse: "collapse",
        border: "none",
        width: '100%'
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              border: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              1.

            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderImage: "initial",
              borderLeft: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-Ortho-1&nbsp;

            </p>
          </td>
          <td
            style={{
              borderTop: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderImage: "initial",
              borderLeft: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The orthographic transcription (in traditional Chinese characters)
              for each word produced by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              2.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-Jyutping-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The Jyutping romanisation for the citation form(s) of each word
              produced by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              3.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-CitIPA-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The citation phonetic form(s) (in IPA) of each word produced by
              Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              4.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-ActIPA-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The phonetic form (in IPA) for each word that was actually produced
              by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              5.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-Ortho-1 &nbsp; &nbsp;&nbsp;

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The orthographic transcription (in traditional Chinese characters)
              of each syllable produced by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              6.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-Jyutping-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The Jyutping romanisation for the citation form(s) of each syllable
              produced by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              7.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-CitIPA-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The citation phonetic form(s) (in IPA) of each syllable produced by
              Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              8.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-ActIPA-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The phonetic form (in IPA) for each syllable that was actually
              produced by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              9.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              PHON-Phoneme-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The actual phonetic segments (in IPA) produced by Talker 1 (mother).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              10.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              PHON-Phoneme-PreCor-1

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The phonetic segments (in IPA) produced by Talker 1 (mother) as
              determined by a first-pass forced-alignment analysis procedure
              (Groups 2 and 3 only). Any post-hoc corrections to this tier are
              reflected in the tier PHON-Phoneme-1.

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              11.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-Ortho-2&nbsp;

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The orthographic transcription (in Traditional Chinese characters)
              for each word produced by Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              // height: "25.6pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              12.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              // height: "25.6pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-Jyutping-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              // height: "25.6pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The Jyutping romanisation for the citation form(s) of each word
              produced by Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              13.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-CitIPA-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The citation phonetic form(s) (in IPA) of each word produced by
              Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              14.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              WORD-ActIPA-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The phonetic form (in IPA) for each word that was actually produced
              by Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              15.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-Ortho-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The orthographic transcription (in Traditional Chinese characters)
              of each syllable produced by Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              16.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-Jyutping-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The Jyutping romanisation for the citation form(s) of each syllable
              produced by Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              17.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-CitIPA-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The citation phonetic form(s) (in IPA) of each syllable produced by
              Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              18.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              SYLL-ActIPA-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The phonetic form (in IPA) for each syllable that was actually
              produced by Talker 2 (child).

            </p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              borderRight: "1pt solid windowtext",
              borderBottom: "1pt solid windowtext",
              borderLeft: "1pt solid windowtext",
              borderImage: "initial",
              borderTop: "none",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              19.

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "3.6pt",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
              }}
            >

              PHON-Phoneme-2

            </p>
          </td>
          <td
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderBottom: "1pt solid windowtext",
              borderRight: "1pt solid windowtext",
              padding: "0cm 5.4pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "0cm",
                marginLeft: "0cm",
                lineHeight: "normal",
                fontSize: 15,
                background: "white"
              }}
            >

              The actual phonetic segments (in IPA) produced by Talker 2 (child).

            </p>
          </td>
        </tr>
      </tbody>
    </table>


  );
}

export default CodingTaggingTable;
