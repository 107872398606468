import React from 'react';

function NaturalLanguagesTable() {
  return (
        <table
          style={{
            border: "none",
            marginLeft: "-.25pt",
            borderCollapse: "collapse"
          }}
        >
          <tbody>
            <tr>
              <td
                rowSpan={2}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Gp
                  </span>
                </p>
              </td>
              <td
                rowSpan={2}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Native languages
                  </span>
                </p>
              </td>
              <td
                rowSpan={2}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Dyad (n)
                  </span>
                </p>
              </td>
              <td
                rowSpan={2}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Age
                  </span>
                </p>
              </td>
              <td
                rowSpan={2}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Boys : girls&nbsp;
                  </span>
                </p>
              </td>
              <td
                colSpan={5}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Years of residence in HK
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                colSpan={3}
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Education
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &lt; 2
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    2-4
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    5-10
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    11-20
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &gt; 20
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Sec.
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Dipl.
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Degree/ above
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    HKC
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    29
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    20-27
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderRight: "none",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderTop: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    14: 15
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "right"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    0
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    0
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    0
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    26
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    3
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    5
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    19
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: "1pt solid windowtext",
                  borderLeft: "none",
                  borderBottom: "1pt solid windowtext",
                  borderRight: "none",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    2
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "1pt solid windowtext",
                  borderLeft: "none",
                  borderBottom: "1pt solid windowtext",
                  borderRight: "none",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Putonghua
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "1pt solid windowtext",
                  borderLeft: "none",
                  borderBottom: "1pt solid windowtext",
                  borderRight: "none",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    27
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "1pt solid windowtext",
                  borderLeft: "none",
                  borderBottom: "1pt solid windowtext",
                  borderRight: "none",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    14-40
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "1pt solid windowtext",
                  borderLeft: "none",
                  borderBottom: "1pt solid windowtext",
                  borderRight: "none",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    13: 14
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    5
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    8
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    11
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    4
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    21
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    3
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    Urdu, Punjabi, or Tamil
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    12
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    6-59
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify"
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    4: 8&nbsp;
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;0
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    0
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    1
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    9
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    5
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    0
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderImage: "initial",
                  borderBottom: "1pt solid windowtext",
                  padding: "0cm 5.4pt",
                  height: "15.75pt",
                  verticalAlign: "top"
                }}
              >
                <p
                  style={{
                    marginTop: "0cm",
                    marginRight: "0cm",
                    marginBottom: "8.0pt",
                    marginLeft: "0cm",
                    lineHeight: "107%",
                    fontSize: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      lineHeight: "107%",
                    }}
                  >
                    6
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

  );
}

export default NaturalLanguagesTable;
