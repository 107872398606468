import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  // Button,
  // TextField,
  // InputAdornment,
  // IconButton,
} from "@material-ui/core";
// import { useQuery } from "react-query";
// import { Search } from "@material-ui/icons";
import NavBar from "../components/NavBar";
// import WordResultsList from "../components/WordResultsList";
import SentenceResultsList from "../components/SentenceResultsList";
import NavigationLinks from '../components/NavigationLinks'
import { useSearchState, useSearchDispatch } from '../contexts/SearchContext'

// interface ParamTypes {
//   wordid?: string;
// }

function SentenceResultPage() {
  const classes = useStyles();
  const history = useHistory();
  const {selectedWord} = useSearchState()
  const searchDispatch = useSearchDispatch()
  // const params = useParams<ParamTypes>();
  // const { wordid } = params;
  function handleSentenceClick(sentence_id: number, cha_file_id: number) {
    console.log(`sentence #${sentence_id} clicked, for file #${cha_file_id}`);
    searchDispatch({
      type: 'SELECT_SENTENCE',
      payload: {
        selectedSentenceId: sentence_id,
        selectedFileId: cha_file_id
      }
    })
    history.push(`/file`);
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <NavBar />
      <div className={classes.navigation}>
        <NavigationLinks stage='sentence'/>
      </div>
      {selectedWord && (
        <SentenceResultsList
          wordMorTag={selectedWord}
          onClick={handleSentenceClick}
        />
      )}
    </Paper>
  );
}

export default SentenceResultPage;

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    margin: 0,
    height: "100vh",
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  navigation: {
    padding: theme.spacing(1)
  },
}));
