import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Link,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import NavBar from "../components/NavBar";
import SearchIcon from '@material-ui/icons/Search';
import MenuBookIcon from '@material-ui/icons/MenuBook';

function LandingPage() {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <NavBar />
      <div className={classes.landingContainer}>
        <div className={classes.introContainer}>
          <Typography variant="h5">Multi-ethnic Hong Kong Cantonese Corpus (MeHKCC)</Typography>
          <p>
            The Multi-ethnic Hong Kong Cantonese Corpus (MeHKCC) is corpus of annotated child-directed speech (CDS) speech and adult-directed speech (ADS) in Hong Kong Cantonese (HKC) spoken by mothers in Hong Kong with different language backgrounds, native in (1) HKC, (2) Putonghua, and (3) South-Asian languages. The goal of developing this corpus is to provide a resource for accurate descriptions of the speech sound, vocabulary and syntax in the language input received by young children of these mothers staying in Hong Kong.
          </p>
        </div>
        <div className={classes.nextContainer}>
          <Link href="#/intro" className={classes.link}>
            <MenuBookIcon className={classes.icon} />
            Read more
          </Link>
          <Link href="#/search" className={classes.link}>
            <SearchIcon className={classes.icon} />
            Search
          </Link>
        </div>
      </div>
    </Paper>
  );
}

export default LandingPage;

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    margin: 0,
    height: "100vh",
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  navigation: {
    padding: theme.spacing(1)
  },
  landingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  introContainer: {
    // flex: 2,
    justifyContent: 'flex-start',
    padding: theme.spacing(3)
  },
  nextContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(0.5)
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    padding: theme.spacing(3)
  }
}));
